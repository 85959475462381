<template>
  <div>
    <SigmaNetChart />
  </div>
</template>

<script>
import SigmaNetChart from "@/components/charts/SigmaNetChart.vue";

export default {
  components: {
    SigmaNetChart,
  },
};
</script>

<style>
</style>